/* This part controls the css for the app component asides the authentication part */

:hover:not(.selected){
  transition: color 300ms ease-in, background-color 300ms ease-in;
}

::-webkit-scrollbar{
  width: 8px;
  padding: 2px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb{
  -webkit-box-shadow: inset 0 0 6px 0 rgba(0,0,0,0.2); 
  box-shadow: inset 0 0 6px 0 rgba(0,0,0,0.2);
  border-radius: 16px;
  width: 4px;
  background-color: var(--emr-primary-light-colour);
}

body{
  min-height: 100vh;
  background-color: var(--emr-background-colour);
}

nav{
  min-height: 64px;
  background-color: var(--emr-background-colour);
  /* border-bottom: 0.5px solid var(--emr-primary-light-colour); */
  position: fixed;
  z-index: 3;
}

main{
  min-height: max-content;
  padding-top: 64px;
}

.emr-quick-button{
  width: 100%;
  height: min-content;
  padding: var(--emr-default-padding);
  display: flex;
  align-items: center;
  border-radius: var(--emr-round-corners);
  box-shadow: var(--emr-default-shadow);
  transition: all 250ms ease-in, opacity 250ms linear;
}

.emr-quick-button:hover{
  box-shadow: var(--emr-high-shadow);
  cursor: pointer;
}

.emr-quick-button:active{
  box-shadow: inset 0px 0px 6px 2px rgba(0, 0, 0, 0.15);
  transform: none;
}

.emr-quick-button .emr-icon-bg{
  width: 40px;
  height: 40px;
}

.emr-quick-button-light-bg{
  /* background: var(--emr-light-gradient); */
  background-color: var(--emr-background-colour);
  color: var(--emr-accent-colour);
}

.emr-quick-button-light-bg:hover{
  background-color: white;
}

.emr-quick-button-light-bg .emr-icon-bg{
  background-color: var(--emr-secondary-colour);
}

.emr-quick-button i{
  color: inherit;
}

.emr-quick-button-dark-bg{
  /* background: var(--emr-dark-gradient); */
  background-color: var(--emr-secondary-colour);
  color: var(--emr-secondary-colour);
}

.emr-quick-button-dark-bg:hover{
  background-color: var(--emr-primary-colour);
}

.emr-quick-button-dark-bg .emr-icon-bg{
  background-color: var(--emr-accent-colour);
}

.emr-quick-button-text{
  display: inline-block;
  flex-grow: 2;
  vertical-align: middle;
}

.emr-quick-button-text > .emr-card-headers{
  font-size: 10.5pt;
  width: 100%;
}

.emr-quick-button-light-bg .emr-card-headers{
  color: black;
}

.emr-quick-button-dark-bg .emr-card-headers{
  color: white;
}

.emr-quick-button-notification{
  /* border: 2px solid var(--emr-lighter-grey-colour); */
  box-shadow: inset 0px 0px 8px 2px rgba(0, 0, 0, 0.15), 4px 4px 16px 0px rgba(0,0,0,0.7);
}

.emr-headers{
  padding-left: 8px;
}

.emr-coming-soon{
  width: 100%;
  height: 128px;
  position: relative;
}

.emr-coming-soon-text{
  width: 100%;
  height: 16px;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  margin: auto;
  font-size: 8pt;
  color: var(--emr-grey-colour);
  font-style: italic;
  text-align: center;
}

.emr-selectable-items-group{
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.emr-selectable-item{
  min-width: 20%;
  padding: 16px;
  margin-right: 24px;
  margin-bottom: 12px;
  color: var(--emr-darker-grey-colour);
  border: 2px solid var(--emr-primary-light-colour);
  border-radius: 32px;
}

.emr-selectable-item:hover{
  border-color: var(--emr-secondary-colour);
  background-color: var(--emr-light-colour);
}

.emr-selectable-item:active, .emr-selectable-item.selected{
  color: white;
  border-color: var(--emr-accent-colour);
  background-color: var(--emr-secondary-colour);
  box-shadow: var(--emr-default-shadow);
}

.emr-selectable-item > p{
  width: 100%;
  line-height: inherit;
  font-size: 9.5pt;
  font-weight: 700;
  text-align: center;
  color: inherit;
  background-color: transparent;
}

.emr-notification-box{
  display: none;
  width: 85%;
  margin: auto;
  position: fixed;
  right: 0; bottom: 32px; left: 0;
  z-index: 10;
  animation: 300ms ease-in 1 slideUp;
}

.emr-notification-box.show{
  display: block;
}

@keyframes slideUp{
  from{
    transform: translateY(50%);
    opacity: 0.5;
  }

  to{
    transform: translateY(0%);
    opacity: 1;
  }
}

@media only screen and (min-width: 768px){
  .emr-notification-box{
    width: 36vw;
    right: 24px; left: auto;
  }
}

@media only screen and (min-width: 1224px){
  .emr-notification-box{
    width: 22vw;
  }
}
